<template>
  <section id="screenshot">
    <div class="container">
      <h2 class="section-title">SCREEN SHOTS</h2>
      <carousel
        autoplay
        loop
        :navigate-to="1"
        :perPageCustom="[
          [0, 1],
          [768, 3],
          [1024, 5],
        ]"
        centerMode
      >
        <slide
          @slideclick="handleSlideClick"
          data-index="0"
          data-name="screen-0"
        >
          <div class="img-screen">
            <img
              src="@/assets/images/screen/screen1.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </slide>
        <slide
          @slideclick="handleSlideClick"
          data-index="1"
          data-name="screen-1"
        >
          <div class="img-screen">
            <img
              src="@/assets/images/screen/screen2.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </slide>
        <slide
          @slideclick="handleSlideClick"
          data-index="2"
          data-name="screen-2"
        >
          <div class="img-screen">
            <img
              src="@/assets/images/screen/screen5.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </slide>
        <slide
          @slideclick="handleSlideClick"
          data-index="3"
          data-name="screen-3"
        >
          <div class="img-screen">
            <img
              src="@/assets/images/screen/screen3.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </slide>
        <slide
          @slideclick="handleSlideClick"
          data-index="4"
          data-name="screen-4"
        >
          <div class="img-screen">
            <img
              src="@/assets/images/screen/screen4.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  methods: {
    handleSlideClick(dataset) {
      console.log(dataset.index, dataset.name);
    },
    someLocalProperty(data) {
      console.log(data);
    },
  },
};
</script>


<style lang="scss" scoped>
#screenshot {
  position: relative;
  /* height: 900px; */

  &::before {
    content: "";
    background-image: url(../../assets/images/bg-screen-shot.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}
.img-screen {
  padding: 0 0.5rem;
}
</style>