<template>
  <section id="commander">
    <div class="container">
      <h2 class="section-title">COMMADER</h2>
      <div class="px-3">
        <div class="commander-box">
          <b-carousel id="carousel-fade" v-model="slide" no-animation :interval="10000">
            <b-carousel-slide v-for="(item, key) in commanders" :key="key">
              <template #img>
                <b-img :src="item.src" alt class="img-fluid" lazy />
              </template>
            </b-carousel-slide>
          </b-carousel>
          <span class="prev" @click="prev()">
            <img src="@/assets/images/left.png" alt="" />
          </span>
          <span class="next" @click="next()">
            <img src="@/assets/images/right.png" alt="" />
          </span>
        </div>
      </div>
      <div class="embed-video backdrop">
        <img
          src="@/assets/images/base-commander.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <p>
        Commanders or also known as the main spaceships. When players already
        connect the METAMASK wallet with the game. each player will receive a
        main spaceship that cannot trade out or remove from the fight.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      commanders: [
        {
          src: require("@/assets/images/commanders/spaceship-00.png"),
        },
        {
          src: require("@/assets/images/commanders/spaceship-01.png"),
        },
        {
          src: require("@/assets/images/commanders/spaceship-02.png"),
        },
        {
          src: require("@/assets/images/commanders/spaceship-03.png"),
        },
        {
          src: require("@/assets/images/commanders/spaceship-04.png"),
        },
      ],
    };
  },
  methods: {
    next() {
      // if (this.slide.length > this.commanders.length - 1) return;
      this.slide++;
    },
    prev() {
      // if (this.slide == 0) return;
      this.slide--;
    },
  },
};
</script>

<style lang="scss" scoped>
#commander {
  position: relative;
  /* height: 900px; */

  &::before {
    content: "";
    background-image: url(../../assets/images/bg-commander.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}
p {
  /* max-width: 544px; */
  font-family: "Offside", cursive;
  font-weight: 400;
  font-size: 28px;
  /* line-height: 47px; */
  padding: 1rem;
  margin: 0;
  @media screen and (max-width: 992px) {
    font-size: 22px;
    padding: 0.75rem;
  }

  @media screen and (max-width: 412px) {
    font-size: 16px;
    padding: 0.5rem;
  }
}

.embed-video {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  /* padding: 70px; */
  /* &.backdrop {
    background: rgba(0, 0, 0, 0.6);
  } */
}
.commander-box {
  position: relative;
  margin: 0 auto;
  height: 400px;
  max-width: 500px;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
    height: 360px;
  }
  @media screen and (max-width: 576px) {
    height: 300px;
  }
  /* background: #fff; */
  .prev {
    cursor: pointer;
    position: absolute;
    left: -20px;
    top: calc(50% - (70px / 2));
    img {
      height: 70px;
    }
    @media screen and (max-width: 576px) {
      left: -10px;
      top: calc(50% - (40px / 2));
      img {
        height: 40px;
      }
    }
  }
  .next {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: calc(50% - (70px / 2));
    img {
      height: 70px;
    }
    @media screen and (max-width: 576px) {
      right: -10px;
      top: calc(50% - (40px / 2));
      img {
        height: 40px;
      }
    }
  }
}
</style>