var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"spaceship"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"section-title"},[_vm._v("SPACESHIP")]),_vm._m(0),_c('div',{staticClass:"spaceship"},[_vm._m(1),_c('div',{staticClass:"ship"},[_c('div',{staticClass:"commander-box"},[_c('b-carousel',{attrs:{"id":"carousel-fade","no-animation":"","interval":10000},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.spaceships),function(item,key){return _c('b-carousel-slide',{key:key,scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('b-img',{staticClass:"img-fluid",attrs:{"src":item.src,"alt":"","lazy":""}})]},proxy:true}],null,true)})}),1)],1)]),_vm._m(2)]),_c('div',{staticClass:"stat"},[_c('div',{staticClass:"vector d-none d-md-flex"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"vector-4"},_vm._l((6),function(r){return _c('span',{key:r},_vm._l((8),function(c){return _c('span',{key:c,class:{ active: r == 6 || (r == 5 && c != 8) }})}),0)}),0)]),_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spaceship-top"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/ship-top.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/ship-left.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/ship-right.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vector-2"},[_c('span',{staticClass:"full"}),_c('span',{staticClass:"full"}),_c('span',{staticClass:"full"}),_c('span',{staticClass:"full"}),_c('span'),_c('span'),_c('span'),_c('span'),_c('span'),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vector-3"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/def.png"),"alt":"def"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/war.png"),"alt":"war"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/posion.png"),"alt":"posion"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/atk.png"),"alt":"atk"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/mag.png"),"alt":"mag"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/paradin.png"),"alt":"paradin"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vector-1 d-none d-md-block"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/vector-stat.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-block d-md-none"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/def.png"),"alt":"def"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/war.png"),"alt":"war"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/posion.png"),"alt":"posion"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/atk.png"),"alt":"atk"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/mag.png"),"alt":"mag"}})]),_c('div',{staticClass:"col px-1 px-md-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/spaceship/paradin.png"),"alt":"paradin"}})])])])
}]

export { render, staticRenderFns }