<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-5 order-1 order-md-0">
          <h2 class="section-title">game story</h2>
          <p>
            GALATIC SPACE is a game created from an idea of bringing blockchain
            and NFT technology incorporated in the game. Players will not only
            have an enjoyable time from playing the game, but also earn some
            money from the game.
          </p>
          <p>
            GALATIC SPACE is an IDEE game so players spend less time playing,
            but get the same pleasure as playing other games.
          </p>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-7 order-0 order-md-1">
          <div class="box-embed">
            <img
              src="@/assets/images/embed-video.png"
              alt=""
              class="img-fluid"
            />
            <div class="embed-video">
              <iframe
                class="w-100 h-100"
                src="https://www.youtube.com/embed/KnpK2WgA8qA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="embed-video backdrop">
            <img
              src="@/assets/images/embed-base.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal id="play-video" hide-footer>
      <iframe
        height="315"
        class="w-100"
        src="https://www.youtube.com/embed/WuEH265pUy4"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </b-modal>
  </section>
</template>

<script>
export default {
  methods: {
    play() {
      console.log("play");
      this.$bvModal.show("play-video");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  /* max-width: 544px; */
  font-family: "Offside", cursive;
  font-weight: 400;
  font-size: 28px;
  /* line-height: 47px; */
  padding: 1rem;
  margin: 0;
  @media screen and (max-width: 992px) {
    font-size: 22px;
    padding: 0.75rem;
  }

  @media screen and (max-width: 412px) {
    font-size: 16px;
    padding: 0.5rem;
  }
}
.content {
  position: relative;
  display: flex;
  padding: 1rem;
  div {
    flex: 1;
  }
}
.box-embed {
  position: relative;
  overflow: hidden;
  margin: 1rem 0;
  img {
    cursor: pointer;
  }
  .embed-video {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* z-index: -1; */
    padding: 5%;
    &.backdrop {
      background: rgba(0, 0, 0, 0.6);
    }
  }
}
</style>