<template>
  <div class="landing">
    <Header />
    <Container>
      <SectionGameStory />
      <SectionGameCommander />
      <SectionSpaceship />
      <SectionPlayToEarn />
      <SectionRoadmap />
      <SectionScreenShot />
    </Container>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Container from "@/components/Container";
import Footer from "@/components/Footer";
import SectionGameStory from "@/components/section/GameStory";
import SectionGameCommander from "@/components/section/GameCommander";
import SectionScreenShot from "@/components/section/ScreenShot";
import SectionSpaceship from "@/components/section/Spaceship";
import SectionRoadmap from "@/components/section/Roadmap";
import SectionPlayToEarn from "@/components/section/PlayToEarn";
export default {
  name: "Home",
  components: {
    Header,
    Container,
    Footer,
    SectionGameStory,
    SectionGameCommander,
    SectionScreenShot,
    SectionSpaceship,
    SectionPlayToEarn,
    SectionRoadmap
  },
};
</script>

<style lang="scss" scoped>
.landing {
  scroll-behavior: smooth;
}
</style>

