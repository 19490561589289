<template>
  <div id="header" class="header">
    <HeaderNavbar />
    <HeaderBanner />
  </div>
</template>

<script>
import HeaderNavbar from "@/components/HeaderNavbar";
import HeaderBanner from "@/components/HeaderBanner";
export default {
  components: {
    HeaderNavbar,
    HeaderBanner,
  },
};
</script>

<style lang="scss" scoped>
</style>