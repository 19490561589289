<template>
  <section id="spaceship">
    <div class="container">
      <h2 class="section-title">SPACESHIP</h2>
      <div class="spaceship-top">
        <img src="@/assets/images/spaceship/ship-top.png" alt class="img-fluid" />
      </div>
      <!-- <div class="battery">
        <span class="prev">
          <img
            src="@/assets/images/spaceship/battery.png"
            alt=""
            width="30px"
          />
        </span>
        <span class="form"></span>
      </div>
      <div class="power">
        <span class="prev">
          <img src="@/assets/images/spaceship/flash.png" alt="" width="30px" />
        </span>
        <span class="form"></span>
      </div>-->
      <div class="spaceship">
        <div class="side">
          <img src="@/assets/images/spaceship/ship-left.png" alt class="img-fluid" />
        </div>
        <div class="ship">
          <div class="commander-box">
            <b-carousel id="carousel-fade" v-model="slide" no-animation :interval="10000">
              <b-carousel-slide v-for="(item, key) in spaceships" :key="key">
                <template #img>
                  <b-img :src="item.src" alt class="img-fluid" lazy />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div>
          <!-- <img
            src="@/assets/images/spaceship/spaceship01.png"
            alt=""
            class="img-fluid"
          />-->
        </div>
        <div class="side">
          <img src="@/assets/images/spaceship/ship-right.png" alt class="img-fluid" />
        </div>
      </div>
      <div class="stat">
        <div class="vector d-none d-md-flex">
          <div class="vector-2">
            <span class="full"></span>
            <span class="full"></span>
            <span class="full"></span>
            <span class="full"></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="vector-3">
            <div class="row mx-0">
              <div class="col px-1 px-md-2">
                <img src="@/assets/images/spaceship/def.png" alt="def" class="img-fluid" />
              </div>
              <div class="col px-1 px-md-2">
                <img src="@/assets/images/spaceship/war.png" alt="war" class="img-fluid" />
              </div>
              <div class="col px-1 px-md-2">
                <img src="@/assets/images/spaceship/posion.png" alt="posion" class="img-fluid" />
              </div>
              <div class="col px-1 px-md-2">
                <img src="@/assets/images/spaceship/atk.png" alt="atk" class="img-fluid" />
              </div>
              <div class="col px-1 px-md-2">
                <img src="@/assets/images/spaceship/mag.png" alt="mag" class="img-fluid" />
              </div>
              <div class="col px-1 px-md-2">
                <img src="@/assets/images/spaceship/paradin.png" alt="paradin" class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="vector-4">
            <span v-for="r in 6" :key="r">
              <span v-for="c in 8" :key="c" :class="{ active: r == 6 || (r == 5 && c != 8) }"></span>
            </span>
          </div>
        </div>
        <div class="vector-1 d-none d-md-block">
          <img src="@/assets/images/spaceship/vector-stat.png" alt class="img-fluid" />
        </div>
        <div class="d-block d-md-none">
          <div class="row mx-0">
            <div class="col px-1 px-md-2">
              <img src="@/assets/images/spaceship/def.png" alt="def" class="img-fluid" />
            </div>
            <div class="col px-1 px-md-2">
              <img src="@/assets/images/spaceship/war.png" alt="war" class="img-fluid" />
            </div>
            <div class="col px-1 px-md-2">
              <img src="@/assets/images/spaceship/posion.png" alt="posion" class="img-fluid" />
            </div>
            <div class="col px-1 px-md-2">
              <img src="@/assets/images/spaceship/atk.png" alt="atk" class="img-fluid" />
            </div>
            <div class="col px-1 px-md-2">
              <img src="@/assets/images/spaceship/mag.png" alt="mag" class="img-fluid" />
            </div>
            <div class="col px-1 px-md-2">
              <img src="@/assets/images/spaceship/paradin.png" alt="paradin" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      spaceships: [
        {
          src: require("@/assets/images/commanders/Gif_web1.gif")
        },
        {
          src: require("@/assets/images/commanders/Gif_web2.gif")
        },
        {
          src: require("@/assets/images/commanders/Gif_web3.gif")
        },
        {
          src: require("@/assets/images/commanders/Gif_web4.gif")
        },
        {
          src: require("@/assets/images/commanders/Gif_web5.gif")
        },
        {
          src: require("@/assets/images/commanders/Gif_web6.gif")
        }
      ]
    };
  }
};
</script>


<style lang="scss" scoped>
#spaceship {
  position: relative;
  /* height: 900px; */

  &::before {
    content: "";
    background-image: url(../../assets/images/bg-spaceship.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}
.battery {
  position: absolute;
  right: 16px;
  top: 16px;
  border: 1px solid #00cece;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 200px;
  overflow: hidden;
  span {
    display: flex;
    align-items: center;
    height: 35px;

    padding: 0.25rem 1rem;
    &.prev {
      background: rgba(39, 67, 93, 0.2);
      border-right: 1px solid #00cece;
      justify-content: center;
      width: 65px;
    }
    &.form {
      background: rgba(29, 163, 221, 0.2);
      width: 100%;
    }
  }
}
.power {
  position: absolute;
  right: 16px;
  top: calc(16px + 35px + 16px);
  border: 1px solid #00cece;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 200px;
  overflow: hidden;
  span {
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0.25rem 1rem;
    &.prev {
      background: rgba(29, 163, 221, 0.2);
      border-right: 1px solid #00cece;
      justify-content: center;
      width: 65px;
    }
    &.form {
      background: rgba(29, 163, 221, 0.2);
      width: 100%;
    }
  }
}
.stat {
  max-width: 992px;
  margin: 0 auto;
  .vector {
    display: flex;
    .vector-2 {
      display: flex;
      /* height: 35px; */
      align-items: flex-end;
      width: 23%;
      position: relative;
      margin-bottom: 0.5rem;
      span {
        overflow: hidden;
        position: relative;
        width: 10%;
        height: 25px;
        /* margin-right: 6px; */
        &:before {
          content: "";
          position: relative;
          width: 43%;
          top: -8px;
          height: 60px;
          right: -12px;
          transform: rotate(-25deg);
          background: #25e3ff;
          opacity: 0.3;
          display: block;
          z-index: 0;
        }
        &.full {
          &:before {
            opacity: 1;
          }
        }
      }
    }
    .vector-3 {
      display: flex;
      /* height: 35px; */
      width: 58%;
      position: relative;
      padding: 0 1rem;
      top: 2rem;
      img {
        &:hover {
          filter: drop-shadow(1px 1px 6px #00e0ff);
          cursor: pointer;
        }
      }
    }
    .vector-4 {
      display: block;
      /* height: 35px; */
      width: 19%;
      position: relative;
      margin-bottom: 0.5rem;
      span {
        display: flex;
        width: 100%;
        margin: 2px;
        span {
          background: #00c3ff;
          height: 8.5px;
          width: 21px;
          opacity: 0.4;
          &.active {
            background: #ff7981;
          }
        }
      }
    }
  }
  .vector-1 {
  }
}
.spaceship-top {
  max-width: 414px;
  margin: 0 auto;
  text-align: center;
}
.spaceship {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: relative;
  }
  .side {
    padding: 3rem 0;
    width: 120px;
  }
  .ship {
    padding: 2rem 1rem;
    max-width: 600px;
    position: relative;
    .commander-box {
      position: relative;
      margin: 0 auto;
      height: 300px;
      // padding-left: 4rem;
      // padding-right: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        // padding-left: 3rem;
        // padding-right: 3rem;
        height: 360px;
      }
      @media screen and (max-width: 576px) {
        height: 300px;
      }
    }
    &:before {
      position: absolute;
      content: "";
      background-image: url(../../assets/images/spaceship/ship-bg.png);
      background-repeat: no-repeat;
      background-size: 65%;
      background-position: center;
      opacity: 0.3;
      /* max-width: 500px; */
      /* width: 100%; */
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  }
}
</style>